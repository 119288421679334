<template>
  <div class="not-page">
    <img src="//static.buwangchuxin.com/red_open_palm/image/new/404.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'NotPage'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
    .not-page{
        height: 100vh;
        padding-top: 200px;
        font-size: 30px;
        text-align: center;
        color: #444;
        background-color: #f5f5f5;
    }
</style>
